$border: var(--mdc-custom-border);
$cta: var(--mdc-theme-primary);

.gameWrapper {
	border-radius: 8px;
	border: solid 1px $border;

	max-width: 100%;
	max-height: 100%;

	overflow: hidden;

	.header {
		border-bottom: solid 1px $border;
		height: 64px;

		display: flex;
		align-items: center;

		font-size: 18px;
		font-weight: 500;

		padding-right: 1rem;

		.divider {
			width: 1px;
			background-color: $border;
			height: 100%;
		}

		.icon {
			width: 26px;
		}

		& > div:not(.divider) {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			padding: 0 1rem;
		}
	}

	.map {
		--size: 35px;

		position: relative;
		top: -1px;
		left: -1px;
		right: -1px;
		bottom: -1px;

		margin-right: -2px;

		overflow: auto;
		overflow-y: auto;

		max-height: 60vh;

		&.small {
			--size: 60px;

			.gridButton img {
				height: 36px !important;
				width: auto !important;

				&[alt="flag"] {
					height: 28px !important;
				}
			}
		}
	}

	.map table {
		font-size: 100%;
		font-family: san-serif;
		font-weight: bold;
		display: inline-block;
		border-spacing: 0 !important;

		gap: 0;
	}

	.map table,
	.map td,
	.map tr {
		border-collapse: collapse !important;
		border-spacing: 0 !important;
	}

	.map td {
		$size: var(--size);
		width: $size;
		min-width: $size;
		height: $size;
		min-height: $size;

		margin: 0;
		padding: 0;

		border: solid 1px $border;
	}

	.map .gridButton {
		border: none;
		border-radius: 0;

		background-color: transparent;

		cursor: pointer;

		text-align: center;
		font-family: 'Inter', sans-serif;

		width: 100%;
		height: 100%;

		font-size: 20px;
		font-weight: 700 !important;

		display: flex;
		align-items: center;
		justify-content: center;

		position: relative;

		&::after {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			background-color: #000000;

			opacity: 0;
		}

		&:hover::after,
		&:focus::after,
		&.pressed::after {
			opacity: 0.06;
		}

		&:active::after {
			opacity: 0.12;
		}

		&:focus::after {
			transition: 75ms;
		}

		img {
			height: 22px;
		}
	}

	.actionRow {
		padding: 1rem;
	}
}

// Custom button
.customButton {
	outline: 0;

	display: inline-flex;
	align-items: center;
	gap: 8px;

	font-size: 0.875rem;
	font-weight: 500;

	background-color: transparent;
	border: solid 1px $border;
	border-radius: 999px;

	height: 36px;
	padding: 0 0.875rem;

	letter-spacing: 0.0178571429em;

	cursor: pointer;

	span {
		line-height: 36px;
	}

	// Icons
	.material-icons {
		color: $cta;
		font-size: 24px;

		&:first-child {
			margin-left: -0.5rem;
		}
	}
}

// Spacer
.spacer {
	flex: 1 0 auto;
}

// Flex row
.flexRow {
	display: flex;
	justify-content: center;
	margin: 3rem 0;
	width: 50%;
	min-width: 400px;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;

		&.divider {
			height: 75%;
			width: 1px;
			background-color: $border;
		}

		&:not(.divider) {
			flex: 1;
		}
	}
}

// Button row
.buttonRow {
	display: flex;
	gap: 8px;
}

// Banners
.banner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding: 1rem 0;

	position: fixed;
	top: 1px;
	left: 0;
	right: 0;
	bottom: 1px;

	background-color: rgba(255, 255, 255, 0.85);
	backdrop-filter: blur(2px);

	border-bottom: solid 1px $border;

	z-index: 5;

	.mdc-button--outlined {
		background-color: #fff !important;
	}
}

@media screen and (max-width: 786px) {
	.gameWrapper {
		height: 100vh;
		max-height: initial;
		border-radius: 0;
		border: none;
	}

	.flexRow, .buttonRow {
		flex-direction: column;
	}

	.flexRow {
		> * {
			padding: 0.5rem;
		}

		.divider {
			width: 50% !important;
			height: 1px !important;
			padding: 0;
			align-self: center;
		}
	}

	.customButton {
		text-align: center;
		justify-content: center;
	}

	.map {
		max-height: calc(100vh - 176px) !important;
		--size: 50px !important;

		table {
			margin-bottom: -6px;
		}
	}

	.header {
		box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
	}

	.actionRow {
		position: relative;

		box-shadow: 0 -2px 4px -1px rgb(0 0 0 / 20%), 0 -4px 5px 0 rgb(0 0 0 / 14%), 0 -1px 10px 0 rgb(0 0 0 / 12%);

		&::after {
			content: "";
			
			position: absolute;
			top: 99%;
			left: 0;
			right: 0;
			
			height: 60px;
			background-color: #fff;

			z-index: 2;
		}
	}
}
